.dashboard {
    width: 80%;
    margin: 0 auto;
    text-align: center;
    font-size: 90%;
}

@media (max-width: 400px) {
    .dashboard {
        width: 100%;
    }
}

.dashboard img {
    width: 150px;
    margin: 1rem auto 0 auto;
    display: block;
}

.birthdays .birthday {
    margin-right: 0.5rem;
}

.birthdays .birthday:last-child {
    margin-right: 0;
}

.name {
    color: #ff4e55;
    font-weight: bold;
}

.tasks a {
    color: #ff4e55;
}

.tasks .task {
    margin-right: 0.5rem;
}

.tasks .task:last-child {
    margin-right: 0;
}

.newCrews .crew {
    margin-right: 0.5rem;
}

.newCrews .crew:last-child {
    margin-right: 0;
}

.newCrews {
    color: orange;
}