.dropzone {
    background-color: #f0f0f0;
    padding: 1rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.labelArea {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
}

.clickArea {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    cursor: pointer;
}

.preview {
    margin-top: 1rem;
    padding: 0.5rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.thumbnail {
    overflow: hidden;
    font-size: 14px;
     margin-right: 0.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border-radius: 4px;
    color: #404040;
}

.thumbnail img {
    max-width: 40px;
    max-height: 40px;
}

.thumbnail-inner {

}

.thumbnail .icon {
    margin-right: 8px;
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
}