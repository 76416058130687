.load {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}