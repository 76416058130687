.history a {
    color: #ff4e55;
    text-decoration: none;
}

.history a:hover {
    text-decoration: underline;
}

ul.history {
    color: #808080;
    list-style: square;
    padding: 0 0 0 40px;
}

.adminMenu {
    color: #ff4e55;
    text-decoration: none;
    cursor: pointer;
    margin-left: 8px;
}

.adminMenu:hover {
    text-decoration: underline;
}

.revLabel {
    font-size: 80%;
    padding: 2px 4px;
    background-color: #404040;
    color: #fff;
    border-radius: 2px;
}

.deletedItem {
    text-decoration: line-through;
}

.normalItem {
    text-decoration: none;
}

