
.list {
    width: 100%;
    margin: 1rem 0;
}

.item {
    width: 100%;
}

.list td, .list th {
    white-space: nowrap;
}

.list tbody > tr:hover {
    background-color: #e0e0e0;
}

.list tbody a {
    display: block;
    color: #ff4e55;
    text-decoration: none;
}

.list tbody a:hover {
    color: #ff0000;
    text-decoration: underline;
}

.item .titleCell {
    display: flex;
    min-width: 300px;
}

.taskInProgress {
}

.taskCompleted {
    color: #404040 !important;
}

.taskDelayed {
    color: #808080 !important;
}

.taskCanceled {
    text-decoration: line-through !important;
    color: #808080 !important;
}