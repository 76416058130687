.commits {
    width: 100%;
    overflow-x: auto;
}

.commits table {
    display: table;
    width: 100%;
    min-width: 640px;
}

.commit tr {
    width: 100%;
}

.commits tbody > tr:hover {
    background-color: #e0e0e0;
}

.commits td, .commits th {
    white-space: nowrap;
    overflow: hidden;
    padding: 6px 16px;
}

.commit td:nth-child(1) {
    width: 100%;
    max-width: 400px;
    white-space: nowrap;
    overflow: hidden;
}

a.message {
    color: #ff4e55;
    text-decoration: none;
    margin-right: 4px;
}

a.message:hover {
    text-decoration: underline;
}

.issues {
    font-family: 'Fira Code', 'Courier New', monospace;
    font-size: 13px;
    color: #808080;
    font-style: italic;
}

.repoWrap {
    display: inline-flex;
    font-family: 'Fira Code', 'Courier New', monospace;
    font-size: 11px;
}

.repoWrap > div {
    padding: 2px 8px;
}

.repoWrap > div:first-child {
    border-start-start-radius: 4px;
    border-end-start-radius: 4px;
}

.repoWrap > div:last-child {
    border-start-end-radius: 4px;
    border-end-end-radius: 4px;
}

.repo {
    color: #a0a0a0;
    font-size: 11px;
    cursor: pointer;
    display: flex;
}

.branch {
    color: #fff;
    background-color: #e58907;
}

.repo a {
    color: #f0f0f0;
    text-decoration: none;
}

.repo a:hover {
    text-decoration: underline;
}

.files {
    font-family: 'Fira Code', 'Courier New', monospace;
    background-color: #202020;
    padding: 1rem;
    overflow: auto;
    font-size: 13px;
}

.files span {
    cursor: pointer;
}

.addedFile {
    color: #a8d777;
}

.removedFile {
    color: #f35454;
}

.modifiedFile {
    color: #9ac4f1;
}

.addedFileCount {
    background-color: #65C100;
}

.removedFileCount {
    background-color: red;
}

.modifiedFileCount {
    background-color: #67ACFF;
}

.fileCounter {
    font-family: 'Fira Code', 'Courier New', monospace;
    color: #fff;
    padding: 2px 4px;
    font-size: 11px;
    margin-right: 4px;
    border-radius: 4px;
}

.filesCount {
    color: #e0e0e0;
    font-size: 10px;
}

.addedFile:hover, .removedFile:hover, .modifiedFile:hover {
    background-color: #000;
}

.messageBlock {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.action, .ref {
    font-family: 'Fira Code', 'Courier New', monospace;
    color: #fff;
    padding: 2px 8px;
    border-radius: 4px;
    font-size: 11px;
    margin-right: 4px;
}

.actionFeat {
    background-color: #ff4e55;
}

.actionFix {
    background-color: #3c79f5;
}

.actionRefactor {
    background-color: darkgreen;
}

.actionTest {
    background-color: #d3b95f;
}

.actionDocs {
    background-color: #804080;
}

.actionRes {
    background-color: darkcyan;
}

.ref {
    background-color: #e58907;
}