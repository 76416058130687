.issues {
    display: inline-flex;
    gap: 8px;
}

.issue {
    padding: 2px 4px;
    border-radius: 4px;
    font-size: 11px;
    background-color: #000000;
    color: #fff;
    text-decoration: none;
    font-family: 'Fira Code', 'Courier New', monospace;
}