body {
  font-family: "Noto Sans KR", "--apple-system", "Helvetica", "Arial", sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.oc-calendar-day-header {
  padding: 0.2rem;
  justify-content: center;
  border: 1px solid red;
}

.send-email {
  display: flex;
}

.react-calendar__navigation button {
  color: #ff4e55;
  min-width: 44px;
  background: none;
  font-size: 16px;
  margin-top: 8px;
}

.fc-button-primary {
  background-color: #ff4e55 !important;
  border-color: #ff4e55 !important;
}

p > a:link, p > a:visited {
  color: #ff4e55;
}

.fc-event {
  font-size: 11px !important;
}

.vacation-list {
  display: flex;
  background-color: #fff;
  overflow-x: hidden;
  overflow-y: auto;
  flex-direction: column;
}

span.vacation-accented {
  color: red;
  font-size: 13px;
}

.vacation-status-label {
  color: #fff;
  padding: 4px;
  border-radius: 4px;
  font-size: 0.8em;
}

#vacation-period {
  font-size: 120%;
  color: #606060;
  padding: 4px;
  margin-bottom: 0.5rem;
}

.MuiContainer-root {
  width: 100vw;
  padding-left: 0;
  padding-right: 0;
}

.tCenter {
  text-align: center;
}

.refresh-button {
  margin-right: 1rem;
}

.w-md-editor-text {
  height: 100%;
}

.w-md-editor-text-input, .w-md-editor-text-pre {
  font-family: "Noto Sans KR", "--apple-system", "Helvetica", "Arial", sans-serif !important;
  font-size: 16px !important;
  line-height: 150% !important;
}

.wmde-markdown {
  font-family: "Noto Sans KR", "--apple-system", "Helvetica", "Arial", sans-serif !important;
  font-size: 18px !important;
  margin: 10px 0 !important;
}