
.taskId {
    border-radius: 4px;
    color: #fff;
    font-size: 16px;
    padding: 2px 8px;
    margin-right: 8px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #000;
    cursor: pointer;
}