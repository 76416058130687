.inputWrapper {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 50;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.input {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 600px;
    margin: 0 auto;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 1rem;
    border-radius: 0.5rem;
    z-index: 90;
}

.daumPostCode {
    margin-top: 1rem;
    z-index: 99;
}